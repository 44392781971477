import { ClothesInfo } from "../components/Clothes/ClothesInfo/ClothesInfo";
import { Button } from "../components/UI/Button/Button/Button";
import { Text } from "../components/UI/Text/Text";
import { Title } from "../components/UI/Title/Title";
import { GridWrapper } from "../components/UI/Wrappers/GridWrapper/GridWrapper";
import { ShadowWrapper } from "../components/UI/Wrappers/ShadowWrapper/ShadowWrapper";
import { clothesData } from "../data/clothes.data";
import { useActions } from "../services/hooks/actions.hook";

export const Clothes = (): JSX.Element => {
  const { openModal } = useActions();

  const clickAddClothes = () => {
    openModal({ uuid: null, type: "clothes" });
  };

  return (
    <>
      <Title size="h2" text="Товары" />
      <Button
        text="Добавить новую вещь"
        width="20rem"
        margin="0 0 2rem"
        styles="secondary"
        action={clickAddClothes}
      />
      <Text styles="sub-title" text="Pokkot 2024" />
      <GridWrapper>
        {clothesData &&
          clothesData.map((item) => (
            <ShadowWrapper>
              <ClothesInfo
                uuid={item.uuid}
                vendor={item.vendor}
                title={item.title}
                sex={item.sex}
              />
            </ShadowWrapper>
          ))}
      </GridWrapper>
    </>
  );
};
