import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { BasicLayout } from "./layouts/BasicLayout";
import { Main } from "./pages/Main";
import { Stock } from "./pages/Stock";
import { Clothes } from "./pages/Clothes";
import { ClothesSettings } from "./pages/ClothesSettings";
import { Collections } from "./pages/Collections";
import { TechWorks } from "./layouts/TechWorks";

const devmode = true;

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: devmode ? <TechWorks /> : <BasicLayout />,
      children: [
        {
          index: true,
          element: <Main />,
        },
        {
          path: "stock",
          element: <Stock />,
        },
        {
          path: "clothes",
          element: <Clothes />,
        },
        {
          path: "collections",
          element: <Collections />,
        },
        {
          path: "clothes-settings",
          element: <ClothesSettings />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
