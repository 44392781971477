import { SizeInfo } from "../components/Size/SizeInfo/SizeInfo";
import { TypeInfo } from "../components/Type/TypeInfo/TypeInfo";
import { Button } from "../components/UI/Button/Button/Button";
import { Text } from "../components/UI/Text/Text";
import { Title } from "../components/UI/Title/Title";
import { GridWrapper } from "../components/UI/Wrappers/GridWrapper/GridWrapper";
import { ShadowWrapper } from "../components/UI/Wrappers/ShadowWrapper/ShadowWrapper";
import { useActions } from "../services/hooks/actions.hook";
import { useGetAllTypesQuery } from "../services/store/types/types.api";
import { Error } from "../components/UI/Error/Error";
import { Preloader } from "../components/UI/Preloader/Preloader";
import { useGetAllSizesQuery } from "../services/store/sizes/sizes.api";

export const ClothesSettings = (): JSX.Element => {
  const { openModal } = useActions();
  const {
    data: typeData,
    isError: typeError,
    isLoading: typeLoad,
  } = useGetAllTypesQuery();
  const {
    data: sizeData,
    isError: sizeError,
    isLoading: sizeLoad,
  } = useGetAllSizesQuery();

  const clickAddType = () => {
    openModal({ uuid: null, type: "types" });
  };

  const clickAddSize = () => {
    openModal({ uuid: null, type: "sizes" });
  };

  return (
    <>
      <Title size="h2" text="Настройки товаров" />
      <Text text="Типы вещей" styles="sub-title" />
      <Button
        text="Добавить новый тип"
        width="20rem"
        margin="0 0 2rem"
        styles="secondary"
        action={clickAddType}
      />
      <GridWrapper>
        {typeError ? (
          <Error />
        ) : typeLoad ? (
          <Preloader />
        ) : typeData ? (
          typeData.map((item) => (
            <ShadowWrapper key={item.uuid}>
              <TypeInfo uuid={item.uuid} title={item.title} />
            </ShadowWrapper>
          ))
        ) : (
          <Preloader />
        )}
      </GridWrapper>
      <Text text="Размеры" styles="sub-title" />
      <Button
        text="Добавить новый размер"
        width="20rem"
        margin="0 0 2rem"
        styles="secondary"
        action={clickAddSize}
      />
      <GridWrapper>
        {sizeError ? (
          <Error />
        ) : sizeLoad ? (
          <Preloader />
        ) : sizeData ? (
          sizeData.map((item) => (
            <ShadowWrapper key={item.uuid}>
              <SizeInfo
                uuid={item.uuid}
                title={item.title}
                order={item.order}
                types={item.types}
              />
            </ShadowWrapper>
          ))
        ) : (
          <Preloader />
        )}
      </GridWrapper>
    </>
  );
};
