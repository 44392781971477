import { useActions } from "../../../services/hooks/actions.hook";
import { Button } from "../../UI/Button/Button/Button";
import { Image } from "../../UI/Image/Image";
import { Text } from "../../UI/Text/Text";
import { Title } from "../../UI/Title/Title";
import { StockInfoProps } from "../types/stock.type";
import "./StockInfo.css";

export const StockInfo = ({
  price,
  clothes,
  items,
}: StockInfoProps): JSX.Element => {
  const { openModal } = useActions();

  const clickEdit = () => {
    openModal({ uuid: clothes.uuid, type: "stock" });
  };

  return (
    <>
      <div className="stock-clothes">
        <div className="stock-clothes-photo">
          <Image />
        </div>
        <div>
          <Title size="h3" text={clothes.title} />
          <Text styles="info-text-gray" text={clothes.vendor} />
          <Text text={`${price} ₽`} />
          <Text styles="info-text" text={clothes.sex} />
        </div>
      </div>
      <Text text="Наличие:" />
      {items &&
        items.map((item) => (
          <Text
            styles="info-text-gray"
            text={`${item.size.title} - ${item.stock}`}
          />
        ))}
      <Button text="Редактировать" action={clickEdit} />
    </>
  );
};
