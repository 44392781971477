import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { StatusAnswer } from "../../@types/props.type";
import {
  SizeCreateProps,
  SizeProps,
} from "../../../components/Size/types/size.type";

export const sizesApi = createApi({
  reducerPath: "sizes/api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_GATEWAY,
  }),
  tagTypes: ["Sizes"],
  endpoints: (builder) => ({
    getAllSizes: builder.query<SizeProps[], void>({
      query: () => ({
        url: "/sizes",
      }),
      providesTags: ["Sizes"],
    }),
    getSizeByUuid: builder.mutation<SizeProps, string>({
      query: (uuid) => ({
        url: `/sizes/${uuid}`,
      }),
    }),
    addSize: builder.mutation<StatusAnswer, SizeCreateProps>({
      query: (item) => ({
        url: "/sizes",
        method: "POST",
        body: {
          title: item.title,
          order: item.order,
          types: item.types,
        },
      }),
      invalidatesTags: ["Sizes"],
    }),
    editSize: builder.mutation<SizeProps, SizeCreateProps>({
      query: (item) => ({
        url: `/sizes/${item.uuid}`,
        method: "PATCH",
        body: {
          title: item.title,
          order: item.order,
          types: item.types,
        },
      }),
      invalidatesTags: ["Sizes"],
    }),
    deleteSize: builder.mutation<StatusAnswer, string>({
      query: (uuid) => ({
        url: `/sizes/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sizes"],
    }),
  }),
});

export const {
  useGetAllSizesQuery,
  useGetSizeByUuidMutation,
  useAddSizeMutation,
  useEditSizeMutation,
  useDeleteSizeMutation,
} = sizesApi;
