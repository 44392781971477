import { Text } from "../components/UI/Text/Text";
import { Title } from "../components/UI/Title/Title";
import { GridWrapper } from "../components/UI/Wrappers/GridWrapper/GridWrapper";
import { ShadowWrapper } from "../components/UI/Wrappers/ShadowWrapper/ShadowWrapper";

export const Main = (): JSX.Element => {
  return (
    <>
      <Title size="h2" text="Главная" />
      <GridWrapper>
        <ShadowWrapper>
          <Text text="Сделаю какой-нибудь информативный дашборд" />
        </ShadowWrapper>
      </GridWrapper>
    </>
  );
};
