import { useActions } from "../../../services/hooks/actions.hook";
import { Button } from "../../UI/Button/Button/Button";
import { Text } from "../../UI/Text/Text";
import { Title } from "../../UI/Title/Title";
import { CollectionProps } from "../types/collection.type";
import "./CollectionsInfo.css";

export const CollectionsInfo = ({
  uuid,
  title,
  year,
}: CollectionProps): JSX.Element => {
  const { openModal } = useActions();

  const clickEdit = () => {
    openModal({ uuid, type: "collections" });
  };

  return (
    <>
      <Title size="h3" text={title} />
      <Text text={`Год: ${year}`} styles="info-text" />
      <Button text="Редактировать" action={clickEdit} />
    </>
  );
};
