export const clothesData = [
  {
    uuid: "435363456",
    vendor: 4000432,
    title: "Pokkot T-shirt",
    description: "Some",
    compound: "100% cool",
    sex: "unisex",
    collection: "Pokkot",
  },
  {
    uuid: "435329456",
    vendor: 4000433,
    title: "Pokkot T-shirt",
    description: "Some",
    compound: "100% cool",
    sex: "unisex",
    collection: "Pokkot",
  },
  {
    uuid: "946363456",
    vendor: 4000434,
    title: "Pokkot T-shirt",
    description: "Some",
    compound: "100% cool",
    sex: "unisex",
    collection: "Pokkot",
  },
  {
    uuid: "435304556",
    vendor: 4000435,
    title: "Pokkot T-shirt",
    description: "Some",
    compound: "100% cool",
    sex: "unisex",
    collection: "Pokkot",
  },
  {
    uuid: "435382476",
    vendor: 4000432,
    title: "Pokkot T-shirt",
    description: "Some",
    compound: "100% cool",
    sex: "unisex",
    collection: "Pokkot",
  },
];
