import { CollectionsInfo } from "../components/Collections/CollectionsInfo/CollectionsInfo";
import { Button } from "../components/UI/Button/Button/Button";
import { Error } from "../components/UI/Error/Error";
import { Preloader } from "../components/UI/Preloader/Preloader";
import { Title } from "../components/UI/Title/Title";
import { GridWrapper } from "../components/UI/Wrappers/GridWrapper/GridWrapper";
import { ShadowWrapper } from "../components/UI/Wrappers/ShadowWrapper/ShadowWrapper";
import { useActions } from "../services/hooks/actions.hook";
import { useGetAllCollectionsQuery } from "../services/store/collections/collections.api";

export const Collections = (): JSX.Element => {
  const { openModal } = useActions();
  const {
    data: collectionData,
    isError: collectionError,
    isLoading: collectionLoad,
  } = useGetAllCollectionsQuery();

  const clickAddCollection = () => {
    openModal({ uuid: null, type: "collections" });
  };

  return (
    <>
      <Title size="h2" text="Коллекции" />
      <Button
        text="Добавить новую коллекцию"
        width="20rem"
        margin="0 0 2rem"
        styles="secondary"
        action={clickAddCollection}
      />
      <GridWrapper>
        {collectionError ? (
          <Error />
        ) : collectionLoad ? (
          <Preloader />
        ) : collectionData ? (
          collectionData.map((item) => (
            <ShadowWrapper key={item.uuid}>
              <CollectionsInfo
                uuid={item.uuid}
                title={item.title}
                year={item.year}
              />
            </ShadowWrapper>
          ))
        ) : (
          <Preloader />
        )}
      </GridWrapper>
    </>
  );
};
