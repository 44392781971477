import { configureStore } from "@reduxjs/toolkit";
import { modalReducer } from "./modal/modal.slice";
import { typesApi } from "./types/types.api";
import { collectionsApi } from "./collections/collections.api";
import { sizesApi } from "./sizes/sizes.api";

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    [typesApi.reducerPath]: typesApi.reducer,
    [collectionsApi.reducerPath]: collectionsApi.reducer,
    [sizesApi.reducerPath]: sizesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      typesApi.middleware,
      collectionsApi.middleware,
      sizesApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
