import { useActions } from "../../../services/hooks/actions.hook";
import { Button } from "../../UI/Button/Button/Button";
import { Text } from "../../UI/Text/Text";
import { Title } from "../../UI/Title/Title";
import { SizeProps } from "../types/size.type";
import "./SizeInfo.css";

export const SizeInfo = ({
  uuid,
  title,
  order,
  types,
}: SizeProps): JSX.Element => {
  const { openModal } = useActions();

  const clickEdit = () => {
    openModal({ uuid, type: "sizes" });
  };

  const typesList = (): string | string[] => {
    if (types && types.length > 0) {
      return types.map(
        (item, index, arr) =>
          `${index === 0 ? "Типы вещей: " : ""}${item.title}${
            index === arr.length - 1 ? "" : ", "
          }`,
      );
    } else {
      return "Нет отмеченных типов вещей";
    }
  };

  return (
    <>
      <Title size="h3" text={title} />
      <Text text={`Порядок в сетке: ${order}`} styles="info-text" />
      <Text text={typesList()} />
      <Button text="Редактировать" action={clickEdit} />
    </>
  );
};
