import "./Title.css";
import { TitleProps } from "./types/title.type";

export const Title = ({ size, text }: TitleProps): JSX.Element => {
  return (
    <>
      {size === "h1" && <h1>{text}</h1>}
      {size === "h2" && <h2>{text}</h2>}
      {size === "h3" && <h3>{text}</h3>}
    </>
  );
};
