import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  TypeEditProps,
  TypeProps,
} from "../../../components/Type/types/type.type";
import { StatusAnswer } from "../../@types/props.type";

export const typesApi = createApi({
  reducerPath: "types/api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_GATEWAY,
  }),
  tagTypes: ["Types"],
  endpoints: (builder) => ({
    getAllTypes: builder.query<TypeProps[], void>({
      query: () => ({
        url: "/types",
      }),
      providesTags: ["Types"],
    }),
    getTypeByUuid: builder.mutation<TypeProps, string>({
      query: (uuid) => ({
        url: `/types/${uuid}`,
      }),
    }),
    addType: builder.mutation<StatusAnswer, TypeEditProps>({
      query: (item) => ({
        url: "/types",
        method: "POST",
        body: {
          title: item.title,
        },
      }),
      invalidatesTags: ["Types"],
    }),
    editType: builder.mutation<TypeProps, TypeProps>({
      query: (item) => ({
        url: `/types/${item.uuid}`,
        method: "PATCH",
        body: {
          title: item.title,
        },
      }),
      invalidatesTags: ["Types"],
    }),
    deleteType: builder.mutation<StatusAnswer, string>({
      query: (uuid) => ({
        url: `/types/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Types"],
    }),
  }),
});

export const {
  useGetAllTypesQuery,
  useGetTypeByUuidMutation,
  useEditTypeMutation,
  useAddTypeMutation,
  useDeleteTypeMutation,
} = typesApi;
