import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { StatusAnswer } from "../../@types/props.type";
import {
  CollectionEditProps,
  CollectionProps,
} from "../../../components/Collections/types/collection.type";

export const collectionsApi = createApi({
  reducerPath: "collections/api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_GATEWAY,
  }),
  tagTypes: ["Collections"],
  endpoints: (builder) => ({
    getAllCollections: builder.query<CollectionProps[], void>({
      query: () => ({
        url: "/collections",
      }),
      providesTags: ["Collections"],
    }),
    getCollectionByUuid: builder.mutation<CollectionProps, string>({
      query: (uuid) => ({
        url: `/collections/${uuid}`,
      }),
    }),
    addCollection: builder.mutation<StatusAnswer, CollectionEditProps>({
      query: (item) => ({
        url: "/collections",
        method: "POST",
        body: {
          title: item.title,
          year: item.year,
        },
      }),
      invalidatesTags: ["Collections"],
    }),
    editCollection: builder.mutation<CollectionProps, CollectionProps>({
      query: (item) => ({
        url: `/collections/${item.uuid}`,
        method: "PATCH",
        body: {
          title: item.title,
          year: item.year,
        },
      }),
      invalidatesTags: ["Collections"],
    }),
    deleteCollection: builder.mutation<StatusAnswer, string>({
      query: (uuid) => ({
        url: `/collections/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Collections"],
    }),
  }),
});

export const {
  useGetAllCollectionsQuery,
  useGetCollectionByUuidMutation,
  useAddCollectionMutation,
  useEditCollectionMutation,
  useDeleteCollectionMutation,
} = collectionsApi;
