import "./Button.css";
import { ButtonProps } from "../types/button.type";

export const Button = ({
  type = "button",
  styles = "primary",
  width = "100%",
  margin,
  text,
  action,
}: ButtonProps): JSX.Element => {
  const clickButton = () => {
    if (action) action();
  };

  return (
    <button
      type={type}
      className={`button ${styles}`}
      style={{ width: width, margin: margin }}
      onClick={clickButton}
    >
      {text}
    </button>
  );
};
