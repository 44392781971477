import { Text } from "../components/UI/Text/Text";
import { Title } from "../components/UI/Title/Title";
import { TechWorkWrapper } from "../components/UI/Wrappers/TechWorkWrapper/TechWorkWrapper";

export const TechWorks = () => {
  return (
    <TechWorkWrapper>
      <Title size="h1" text="MIYUKON" />
      <Text styles="sub-title" text="Проводятся технические работы" />
    </TechWorkWrapper>
  );
};
