import { useActions } from "../../../services/hooks/actions.hook";
import { Button } from "../../UI/Button/Button/Button";
import { Image } from "../../UI/Image/Image";
import { Text } from "../../UI/Text/Text";
import { Title } from "../../UI/Title/Title";
import { ClothesInfoProps } from "../types/clothes.type";
import "./ClothesInfo.css";

export const ClothesInfo = ({
  uuid,
  vendor,
  title,
  sex,
}: ClothesInfoProps): JSX.Element => {
  const { openModal } = useActions();

  const clickEdit = () => {
    openModal({ uuid, type: "clothes" });
  };

  return (
    <>
      <div className="clothes-info">
        <div className="clothes-info-photo">
          <Image />
        </div>
        <div>
          <Title size="h3" text={title} />
          <Text styles="info-text-gray" text={vendor} />
          <Text text={sex} />
        </div>
      </div>
      <Button text="Редактировать" action={clickEdit} />
    </>
  );
};
