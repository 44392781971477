export const stockData = [
  {
    price: 2490,
    clothes: {
      uuid: "435363456",
      vendor: 4000432,
      title: "Pokkot T-shirt",
      sex: "unisex",
    },
    items: [
      {
        uuid: "09548",
        size: {
          uuid: "62312",
          title: "S",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "64536",
        size: {
          uuid: "29563",
          title: "M",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "23776",
        size: {
          uuid: "65697",
          title: "L",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "98624",
        size: {
          uuid: "09873",
          title: "XL",
          order: 2,
        },
        stock: 2,
      },
    ],
  },
  {
    price: 2490,
    clothes: {
      uuid: "435329456",
      vendor: 4000433,
      title: "Pokkot T-shirt",
      sex: "unisex",
    },
    items: [
      {
        uuid: "09548",
        size: {
          uuid: "62312",
          title: "S",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "64536",
        size: {
          uuid: "29563",
          title: "M",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "23776",
        size: {
          uuid: "65697",
          title: "L",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "98624",
        size: {
          uuid: "09873",
          title: "XL",
          order: 2,
        },
        stock: 2,
      },
    ],
  },
  {
    price: 2490,
    clothes: {
      uuid: "946363456",
      vendor: 4000434,
      title: "Pokkot T-shirt",
      sex: "unisex",
    },
    items: [
      {
        uuid: "09548",
        size: {
          uuid: "62312",
          title: "S",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "64536",
        size: {
          uuid: "29563",
          title: "M",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "23776",
        size: {
          uuid: "65697",
          title: "L",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "98624",
        size: {
          uuid: "09873",
          title: "XL",
          order: 2,
        },
        stock: 2,
      },
    ],
  },
  {
    price: 2490,
    clothes: {
      uuid: "435304556",
      vendor: 4000435,
      title: "Pokkot T-shirt",
      sex: "unisex",
    },
    items: [
      {
        uuid: "09548",
        size: {
          uuid: "62312",
          title: "S",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "64536",
        size: {
          uuid: "29563",
          title: "M",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "23776",
        size: {
          uuid: "65697",
          title: "L",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "98624",
        size: {
          uuid: "09873",
          title: "XL",
          order: 2,
        },
        stock: 2,
      },
    ],
  },
  {
    price: 2490,
    clothes: {
      uuid: "435382476",
      vendor: 4000432,
      title: "Pokkot T-shirt",
      sex: "unisex",
    },
    items: [
      {
        uuid: "09548",
        size: {
          uuid: "62312",
          title: "S",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "64536",
        size: {
          uuid: "29563",
          title: "M",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "23776",
        size: {
          uuid: "65697",
          title: "L",
          order: 2,
        },
        stock: 2,
      },
      {
        uuid: "98624",
        size: {
          uuid: "09873",
          title: "XL",
          order: 2,
        },
        stock: 2,
      },
    ],
  },
];
