import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModalStoreProps } from "../../@types/store.type";

export interface ModalState {
  show: boolean;
  type: "sizes" | "types" | "collections" | "clothes" | "stock" | null;
  uuid: string | null;
}

const initialState: ModalState = {
  show: false,
  type: null,
  uuid: null,
};

export const modalSlice = createSlice({
  name: "modal/slice",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.show = false;
      state.type = null;
      state.uuid = null;
    },
    openModal: (state, action: PayloadAction<ModalStoreProps>) => {
      state.show = true;
      state.type = action.payload.type;
      state.uuid = action.payload.uuid;
    },
  },
});

export const modalActions = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
