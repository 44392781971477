import { StockInfo } from "../components/Stock/StockInfo/StockInfo";
import { Text } from "../components/UI/Text/Text";
import { Title } from "../components/UI/Title/Title";
import { GridWrapper } from "../components/UI/Wrappers/GridWrapper/GridWrapper";
import { ShadowWrapper } from "../components/UI/Wrappers/ShadowWrapper/ShadowWrapper";
import { stockData } from "../data/stock.data";

export const Stock = (): JSX.Element => {
  return (
    <>
      <Title size="h2" text="Склад" />
      <Text styles="sub-title" text="Pokkot 2024" />
      <Text styles="info-text" text="Всего товаров в наличии: 48" />
      <GridWrapper>
        {stockData &&
          stockData.map((item) => (
            <ShadowWrapper>
              <StockInfo
                price={item.price}
                clothes={item.clothes}
                items={item.items}
              />
            </ShadowWrapper>
          ))}
      </GridWrapper>
    </>
  );
};
