import { useActions } from "../../../services/hooks/actions.hook";
import { Button } from "../../UI/Button/Button/Button";
import { Title } from "../../UI/Title/Title";
import { TypeProps } from "../types/type.type";
import "./TypeInfo.css";

export const TypeInfo = ({ uuid, title }: TypeProps): JSX.Element => {
  const { openModal } = useActions();

  const clickEdit = () => {
    openModal({ uuid, type: "types" });
  };

  return (
    <>
      <Title size="h3" text={title} />
      <Button text="Редактировать" action={clickEdit} />
    </>
  );
};
